// src/PrivacyPolicy.js
import React from "react";
import icon from './icon.png'
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

const TermsOfService = () => {

  const navigate = useNavigate();

  return (
    <>
    
   
   <Navbar/>

      <div class="container mx-auto p-8 bg-white shadow-md rounded-lg mt-8">
        <h1 class="text-3xl font-bold mb-6">Terms of Service</h1>
        
        <p class="mb-4"><strong>Effective Date:</strong> August 8th 2024</p>
        
        <h2 class="text-2xl font-semibold mt-4 mb-2">1. Introduction</h2>
        <p class="mb-4">
            Welcome to Notifi! These Terms of Service ("Terms") govern your access to and use of our app and services. By accessing or using Notifi, you agree to comply with these Terms. If you do not agree to these Terms, you should not use our app.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">2. Account Registration</h2>
        <p class="mb-4">
            To use Notifi, you may need to register an account and log in using your Google account. You agree to provide accurate and complete information during the registration process and to update your information as needed to keep it accurate.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">3. Use of the App</h2>
        <p class="mb-4">
            You agree to use Notifi only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of Notifi complies with all applicable laws and regulations.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">4. Access to Google Calendar</h2>
        <p class="mb-4">
            By logging in with your Google account, you grant Notifi access to your Google Calendar to retrieve information about your calendar events. This access is used solely for the purpose of sending notifications about your events.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">5. Privacy</h2>
        <p class="mb-4">
            Your use of Notifi is also governed by our Privacy Policy, which can be found <a href="/privacy-policy" class="text-blue-500 hover:underline">here</a>. Please review our Privacy Policy to understand how we collect, use, and protect your information.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">6. Intellectual Property</h2>
        <p class="mb-4">
            All content, features, and functionality on Notifi, including but not limited to text, graphics, logos, and software, are the exclusive property of Notifi or its licensors and are protected by copyright, trademark, and other intellectual property laws.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">7. Prohibited Activities</h2>
        <p class="mb-4">
            You agree not to engage in any of the following prohibited activities:
        </p>
        <ul class="list-disc list-inside mb-4 pl-4">
            <li>Using Notifi for any illegal or unauthorized purpose.</li>
            <li>Attempting to interfere with or disrupt the operation of Notifi.</li>
            <li>Accessing or attempting to access any part of Notifi that you are not authorized to access.</li>
            <li>Sharing your account credentials with others.</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-4 mb-2">8. Termination</h2>
        <p class="mb-4">
            We may suspend or terminate your access to Notifi if we believe you have violated these Terms or for any other reason at our discretion. Upon termination, your right to use Notifi will immediately cease, and you must stop using the app.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">9. Disclaimers and Limitation of Liability</h2>
        <p class="mb-4">
            Notifi is provided on an "as is" and "as available" basis. We make no warranties or representations about the accuracy, reliability, or availability of Notifi. To the maximum extent permitted by law, we disclaim all warranties and are not liable for any damages arising from your use of Notifi.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">10. Indemnification</h2>
        <p class="mb-4">
            You agree to indemnify and hold harmless Notifi, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising out of or in connection with your use of Notifi or your violation of these Terms.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">11. Changes to These Terms</h2>
        <p class="mb-4">
            We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website or through the app. Your continued use of Notifi after any changes indicates your acceptance of the revised Terms.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">12. Governing Law</h2>
        <p class="mb-4">
            These Terms are governed by and construed in accordance with the laws of USA. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in USA.
        </p>

        <h2 class="text-2xl font-semibold mt-4 mb-2">13. Contact Us</h2>
        <p class="mb-4">
            If you have any questions about these Terms, please contact us at:
        </p>
        <p class="mb-4">
            <strong>Notifi</strong><br/>
            Email: <a href="mailto:ben@bytebelt.io" class="text-blue-500 hover:underline">ben@bytebelt.io</a><br/>
        </p>
    </div>
    </>
  );
};

export default TermsOfService;
