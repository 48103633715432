// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import "./App.css";
import TermsOfService from "./TermsOfService";

function App() {
  return (
    <Router>
      <Routes>

            <Route path="/" element={<Home/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Router>
  );
}

export default App;
