// src/PrivacyPolicy.js
import React from "react";
import icon from './icon.png';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

const PrivacyPolicy = () => {

  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-4 sm:p-6 lg:p-8 bg-white shadow-md rounded-lg mt-4 sm:mt-6 lg:mt-8">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 sm:mb-6">Privacy Policy</h1>
        
        <p className="mb-2 sm:mb-4"><strong>Effective Date:</strong> August 8th 2024</p>
        
        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">1. Introduction</h2>
        <p className="mb-4">
          Welcome to Notifi! This Privacy Policy explains how we collect, use, and protect your personal information when you use our app. By using Notifi, you agree to the collection and use of information in accordance with this policy.
        </p>

        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">2. Information We Collect</h2>
        <p className="mb-4">
          When you use Notifi, we may collect the following types of information:
        </p>
        <ul className="list-disc list-inside mb-4 pl-4">
          <li><strong>Google Account Information:</strong> When you log in via Google, we collect your Google account information, including your email address, profile picture, and unique user ID.</li>
          <li><strong>Calendar Information:</strong> We access your Google Calendar to retrieve information about your events, such as event titles, dates, times, and reminders.</li>
        </ul>

        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">3. How We Use Your Information</h2>
        <p className="mb-4">
          We use the information we collect to:
        </p>
        <ul className="list-disc list-inside mb-4 pl-4">
          <li>Provide and improve our services.</li>
          <li>Send you notifications about your calendar events.</li>
          <li>Personalize your experience with Notifi.</li>
        </ul>

        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">4. How We Share Your Information</h2>
        <p className="mb-4">
          We do not sell, trade, or otherwise transfer your personal information to outside parties, except in the following circumstances:
        </p>

        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">5. Data Security</h2>
        <p className="mb-4">
          We implement a variety of security measures to maintain the safety of your personal information. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">6. Your Choices</h2>
        <p className="mb-4">
          You have the following choices regarding your personal information:
        </p>
        <ul className="list-disc list-inside mb-4 pl-4">
          <li><strong>Access and Update:</strong> You can access and update your personal information through your account settings.</li>
          <li><strong>Delete:</strong> You can request the deletion of your personal information by contacting us at <a href="mailto:ben@bytebelt.io" className="text-blue-500 hover:underline">ben@bytebelt.io</a>.</li>
        </ul>

        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">7. Third-Party Links</h2>
        <p className="mb-4">
          Notifi may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices of these third parties. We encourage you to review the privacy policies of any third-party sites or services you visit.
        </p>

        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">8. Changes to This Privacy Policy</h2>
        <p className="mb-4">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date. You are advised to review this Privacy Policy periodically for any changes.
        </p>

        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-4 mb-2">9. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <p className="mb-4">
          <strong>Notifi</strong><br />
          Email: <a href="mailto:ben@bytebelt.io" className="text-blue-500 hover:underline">ben@bytebelt.io</a><br />
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
